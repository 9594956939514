import React from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'

const AboutPageTemplate = (props) => {
  const { title, content, contentComponent } = props
  const PageContent = contentComponent || Content

  return (
    <div>
      <section className='hero is-large layer-1'>
        <img src='/img/ucsd_background.png' />
      </section>
      <div className='column layer-2 is-10 is-offset-1 has-text-centered'>
        <div className='section p-2'>
          <img src='/img/our-story.png' width='200' height='68'/>
        </div>
        <div className='section p-6 mt-2'>
          <img src='/img/our-story-1.png' width='821' height='244'/>
        </div>
        <div className='section p-6 '>
          <img src='/img/our-story-bar.png' width='129' height='4'/>
        </div>
        <div className='section p-6 '>
          <img src='/img/our-story-2.png' width='821' height='244'/>
        </div>
        <div className='section p-6 '>
          <img src='/img/our-story-pic.png' width='821' height='244'/>
        </div>
        <div className='section p-2 '>
          <img src='/img/today.png' width='160' height='48'/>
        </div>
        <div className='section p-6 '>
          <img src='/img/today-1.png' width='821' height='244'/>
        </div>
        <div className='section p-6 '>
          <div className='columns is-vcentered'>
            <div className='column'>
              <img src='/img/today-2.png' width='300' height='210'/>
            </div>
            <div className='column'>
              <img src='/img/today-3.png' width='821' height='244'/>
            </div>
          </div>
        </div>
        <div className='section p-2 '>
          <img src='/img/mission.png' width='200' height='68'/>
        </div>
        <div className='section p-6 '>
          <img src='/img/mission-1.png' width='821' height='244'/>
        </div>
        <div className='section p-6 '>
          <img src='/img/mission-2.png' width='500' height='300'/>
        </div>
      </div>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default AboutPageTemplate
